import type { UseFetchOptions } from '#app'
import { defu } from 'defu'

export function useFetchInstance<T>(
  url: string,
  options: UseFetchOptions<T> = {},
) {
  const authStore = useAuthStore()

  const defaults: UseFetchOptions<T> = {
    // this overrides the default key generation, which includes a hash of
    // url, method, headers, etc. - this should be used with care as the key
    // is how Nuxt decides how responses should be deduplicated between
    // client and server
    key: url as string,

    // set user token if connected
    headers: authStore.accessToken
      ? { Authorization: `Bearer ${authStore.accessToken}` }
      : {},

    onResponse(_ctx) {
      // _ctx.response._data = new myBusinessResponse(_ctx.response._data)
    },

    onResponseError(_ctx) {
      // throw new myBusinessError()
    },
  }

  // for nice deep defaults, please use unjs/defu
  const params: any = defu(options, defaults)
  return $fetch<T>(url, params)
}
