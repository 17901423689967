/**
 * * Todo serviço implementa essa classe
 */
export default class BaseService {
  static hostname: string

  static apiHostName: string

  private readonly basePath: string

  constructor(basePath: string) {
    const runtimeConfig = useRuntimeConfig()
    BaseService.hostname = runtimeConfig.public.NUXT_MSAPI_HOSTNAME
    BaseService.apiHostName = runtimeConfig.public.NUXT_API_HOSTNAME

    this.basePath = basePath
  }

  protected getFullBaseUrl(): string {
    return `${BaseService.hostname}/services/${this.basePath}`
  }

  protected getFullApiBaseUrl(): string {
    return `${BaseService.apiHostName}/${this.basePath}`
  }
}
